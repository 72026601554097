<template>
  <!-- registry -->
  <div class="registry-form">
    <el-alert
      v-if="!registryForm.valid"
      class="registry-error"
      :title="registryForm.tip"
      type="error"
      show-icon
    >
    </el-alert>
    <el-form
      ref="registryForm"
      :model="registryForm"
      @keyup.enter.native="registry()"
    >
      <el-form-item>
        <el-input
          v-model="registryForm.phoneCode"
          :placeholder="$t('registry_tip')"
          auto-complete="new-password"
          @focus="formFocus"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              v-model="registryForm.verifyCode"
              :placeholder="$t('registry_tip2')"
              auto-complete="new-password"
              @focus="formFocus"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              style="width:100%;"
              :disabled="sendVerifyCodeBtnStatus.disabled"
              :loading="sendVerifyCodeBtnStatus.loading"
              @click="sendVerifyCode"
            >
              {{
                sendVerifyCodeBtnStatus.disabled
                  ? sendVerifyCodeBtnStatus.time
                  : sendVerifyCodeBtnStatus.text
              }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-button
        :loading="registryLoading"
        class="registry-btn"
        type="primary"
        round
        @click="registry()"
      >
        {{ $t("save") }}
      </el-button>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { geetestMixin } from "../../mixin/geetest.mixin";

export default {
  data() {
    return {
      registryLoading: false,
      committing: false,
      errMsg: " ",
      sendVerifyCodeBtnStatus: {
        disabled: false,
        time: 180,
        text: this.$t("get_vcode"),
        loading: false
      },
      registryForm: {
        phoneCode: "",
        verifyCode: "",
        password: "",
        confirmpassword: "",
        valid: true,
        tip: ""
      },
      registryRules: {
        phoneCode: [
          {
            regexp: /^1[3-9]\d{9}$/,
            message: this.$t("set_correct_mobile"),
            type: "string"
          }
        ],
        verifyCode: []
      },
      memberAgreementVisible: false,
      privacyAgreementVisible: false
    };
  },
  mixins: [geetestMixin],
  methods: {
    ...mapActions([
      "setAccount",
      "setLogin",
      "setDialogVisible",
      "setUserInfo"
    ]),
    ...mapGetters(["lang"]),
    validateRegistryForm(isSendVerifyCode = false) {
      if (!this.registryForm.phoneCode) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("set_common_mobile");
        return;
      }
      if (
        !this.registryRules.phoneCode[0].regexp.test(
          this.registryForm.phoneCode
        )
      ) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip1");
        return;
      }
      // 如果是发送验证码，不会往下发送请求
      if (isSendVerifyCode) {
        return;
      }
      if (!this.registryForm.verifyCode) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip2");
        return;
      }
    },
    formFocus() {
      this.registryForm.valid = true;
    },
    async registry() {
      this.validateRegistryForm();
      if (this.registryForm.valid) {
        this.registryLoading = true;

        const res = await this.$http.post(
          `/api/v1/Accounts/${this.account.id}/Phone`,
          null,
          {
            params: {
              id: this.account.id,
              phoneCode: this.registryForm.phoneCode,
              smsCode: this.registryForm.verifyCode
            }
          }
        );
        if (res.status === 200) {
          this.$notify.success(this.$t("save_success"));
          this.registryForm = {
            valid: true
          };

          const { data } = await this.$http.get(
            "/api/v1/Accounts/" + this.account.id
          );

          if (data.id) {
            this.setAccount(data);
          }
          this.setDialogVisible({ editMobile: false });
        } else {
          this.registryValidateError(res.statusText);
        }
        this.registryLoading = false;
      }
    },
    registryValidateError(tip) {
      this.registryForm.valid = false;
      this.registryForm.tip = tip;
      return;
    },
    async sendVerifyCode() {
      this.formFocus();
      this.validateRegistryForm(true);
      if (!this.registryForm.valid) {
        return;
      }
      try {
        this.sendVerifyCodeBtnStatus.loading = true;
        this.doGeetest();
      } finally {
        this.sendVerifyCodeBtnStatus.loading = false;
      }
    },
    async confirmSendVerifyCode() {
      this.sendVerifyCodeBtnStatus.loading = true;

      const sendRes = await this.$http.post("/api/v1/ActiveCode", null, {
        params: {
          phoneCode: this.registryForm.phoneCode,
          challenge: this.geetest.geetestSuccessData.geetest_challenge,
          validate: this.geetest.geetestSuccessData.geetest_validate,
          seccode: this.geetest.geetestSuccessData.geetest_seccode
        }
      });
      if (sendRes.status === 200) {
        this.sendVerifyCodeBtnStatus.loading = false;

        this.$notify.success(this.$t("registry_field_tip10"));
        this.sendVerifyCodeBtnStatus.disabled = true;
        const timer = setInterval(() => {
          this.sendVerifyCodeBtnStatus.time = this.sendVerifyCodeBtnStatus.time -= 1;
          if (this.sendVerifyCodeBtnStatus.time === 1) {
            this.sendVerifyCodeBtnStatus = {
              disabled: false,
              text: this.$t("resend"),
              time: 180
            };
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.sendVerifyCodeBtnStatus.loading = false;

        return this.registryValidateError(sendRes.statusText);
      }
    },
    async doGeetest() {
      this.errMsg = "";

      if (!this.geetest.geetestObj) {
        /*
          如果this.geetest.geetestFatched==true，则说明已经加载过极验了
          如果this.geetest.showGeetest==false，则说明后台关闭极验了
         */
        if (this.geetest.geetestFatched && !this.geetest.showGeetest) {
          this.confirmSendVerifyCode();
        } else {
          this.initGeetest({
            product: "bind",
            lang: this.getLang.code
          });
        }
      } else {
        if (this.geetest.showGeetest) {
          this.geetestShow();
        } else {
          this.confirmSendVerifyCode();
        }
      }
    },
    // 极验验证成功后回调
    onGeetestSuccess() {
      // 用户通过极验行为验证后做的操作
      this.confirmSendVerifyCode();
    },
    // 极验被禁用后回调
    onDisableGeetest() {
      this.confirmSendVerifyCode();
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
