/*
  极验mixin 
 */
// 导入极验官方给的代码
import "../common/geetest.gt";
import { LANG_MAP } from "../common/constants";
import { mapGetters } from "vuex";
// 极验默认配置
const geetestOptions = {
  product: "popup", // 极验展现形式 可选值有 float、popup、custom、bind
  width: "100%",
  lang: process.env.VUE_APP_I18N || "zh-CN",
  autoShow: true, // 当product为bind时，如果次参数为true，则在极验加载完成后立即显示极验弹窗
  area: null, // 极验绑定的元素，仅在 product为 custom、float、popup时需要传递
  autoRefreshOnLangChange: true // 语言改变时是否自动刷新
};
export const geetestMixin = {
  data() {
    return {
      geetest: {
        geetestSuccessData: null, // 极验用户行为操作成功后的数据
        geetestObj: null, // 极验对象
        geetestLoading: false,
        geetestFatched: false, // 判断是否从服务器获取了极验数据
        showGeetest: false, // 是否使用极验
        sign: "", // 极验降级 用的签名
        geetestRestartCountMax: 5, // 极验重试最大次数
        count: 1,
        geetestOptions: {}
      }
    };
  },
  computed: {
    ...mapGetters(["getLang"])
  },
  watch: {
    getLang(lang) {
      let options = this.geetest.geetestOptions;
      if (options.autoRefreshOnLangChange && this.geetest.geetestObj) {
        this.initGeetest({
          ...options,
          lang: lang.code
        });
      }
    }
  },
  methods: {
    // 初始化极验
    initGeetest(options) {
      if (!options || {}.toString.call(options) !== "[object Object]") {
        console.error("initGeetest方法的参数options必须是一个对象！");
        return;
      }
      let newOptions = Object.assign({}, geetestOptions, options);
      if (
        (newOptions.product === "popup" ||
          newOptions.product === "custom" ||
          newOptions.product === "float") &&
        !newOptions.area
      ) {
        console.error(
          "product为popup、custom、float时options参数中必须有area属性，area属性值可以为css选择器或dom元素！"
        );
        return;
      }
      this.geetest.geetestOptions = newOptions;
      this._geetestRegist_(newOptions);
    },
    // 重置极验
    geetestReset(cb) {
      if (this.geetest.geetestObj) {
        this.geetest.geetestSuccessData = {};
        this.geetest.geetestObj.reset();
        if (typeof cb === "function") {
          cb();
        }
      } else {
        console.error("极验不存在!");
      }
    },
    // 显示极验弹窗，此方法只有在product为bind时才有效
    geetestShow() {
      if (this.geetest.geetestObj) {
        if (this.geetest.geetestOptions.product === "bind") {
          this.geetest.geetestObj.verify();
        } else {
          console.error("极验的product值非bind，无法调用show！");
        }
      } else {
        console.error("极验不存在!");
      }
    },
    // 初始化极验，内部使用
    _initGeetestInternal_(data, options) {
      let that = this;
      let geetest = this.geetest;
      window.initGeetest(
        {
          // 以下 4 个配置参数为必须，不能缺少
          gt: data.gt,
          challenge: data.challenge,
          offline: !data.success, // 表示用户后台检测极验服务器是否宕机
          new_captcha: true, // 用于宕机时表示是新验证码的宕机
          product: options.product, // 产品形式，包括：float，popup，bind
          width: options.width,
          lang: LANG_MAP[options.lang]
        },
        function(captchaObj) {
          if (geetest.geetestObj) {
            try {
              // 如果之前已经初始化过了，则将之前生成的dom移除掉
              geetest.geetestObj.destroy();
            } catch (e) {
              console.error("极验销毁失败", e);
            }
          }
          geetest.geetestObj = captchaObj;
          if (
            options.product === "popup" ||
            options.product === "custom" ||
            options.product === "float"
          ) {
            captchaObj.appendTo(options.area);
          }
          // 为bind模式时极验加载完成后自动弹出极验弹窗
          if (options.autoShow && options.product === "bind") {
            captchaObj.onReady(() => {
              captchaObj.verify();
            });
          }
          geetest.geetestSuccessData = {};
          // 当用户操作后并且通过验证后的回调
          captchaObj.onSuccess(function() {
            let successData = captchaObj.getValidate();
            geetest.geetestSuccessData = successData;
            /*
            这种方式不可采用，原因，作用域会被缓存
            if (typeof options.callback === 'function') {
              options.callback(successData)
            }
            用户行为验证通过后调用回调函数 
          */
            if (typeof that.onGeetestSuccess === "function") {
              that.onGeetestSuccess(successData);
            }
          });
          captchaObj.onError(function(e) {
            console.log(JSON.stringify(e));
          });
        }
      );
    },
    // 调用接口，获取极验数据
    _geetestRegist_(options) {
      if (this.geetest.geetestLoading) {
        return;
      }
      this.geetest.geetestLoading = true;
      this.$http
        .get("/api/v1/GeetestCode")
        .then(res => {
          let data = res.data;
          // TIP 后台需要控制是否开启极验，因此需要判断 enable===true && success===1 才显示极限
          this.geetest.sign = data.sign;
          this.geetest.geetestFatched = true;
          if (
            typeof data.enable == "undefined" ||
            (data.enable === true && data.success === 1)
          ) {
            this.geetest.showGeetest = true;
          } else {
            this.geetest.showGeetest = false;
            this.geetest.geetestLoading = false;
            /*// 如果极验禁用，则调用onDisableGeetest回调
            if(typeof options.onDisableGeetest === 'function'){
              options.onDisableGeetest();
            }*/
            // 如果极验禁用，则调用onDisableGeetest回调
            if (typeof this.onDisableGeetest === "function") {
              this.onDisableGeetest();
            }
            return;
          }
          this.geetest.geetestLoading = false;
          this._initGeetestInternal_(data, options);
        })
        .catch(err => {
          console.error("极验初始化失败", err);
          if (this.geetest.count > this.geetest.geetestRestartCountMax) {
            this.geetest.geetestLoading = false;
            return;
          }
          this.geetest.count++;
          this._geetestRegist_(options);
        });
    }
  },
  beforeDestroy() {
    if (this.geetest.geetestObj) {
      this.geetest.geetestObj.destroy();
    }
  }
};
