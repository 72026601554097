<template>
  <div class="login-form">
    <el-alert
      v-if="!loginForm.valid"
      class="login-error"
      :title="loginForm.tip"
      type="error"
      show-icon
    >
    </el-alert>
    <el-form ref="form" :model="loginForm" @keyup.enter.native="login()">
      <el-form-item>
        <el-input
          v-model="loginForm.account"
          :placeholder="$t('login_tip1')"
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="loginForm.password"
          :placeholder="$t('password')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <div class="flex-j-space login-form-extra">
        <span
          ><el-checkbox v-model="loginForm.remember"></el-checkbox
          >{{ $t("remember_password") }}</span
        >
        <span
          class="forget-password"
          @click="setDialogVisible({ login: false, forgot: true })"
          >{{ $t("forgot_password") }}</span
        >
      </div>
      <el-button
        :loading="loading"
        class="login-btn"
        type="primary"
        round
        @click="login()"
      >
        {{ $t("login") }}
      </el-button>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { setLocalstorage } from "common/util";

export default {
  data() {
    return {
      loading: false,
      forgotDialogVisible: false,
      loginForm: {
        account: "",
        password: "",
        remember: true,
        valid: true,
        tip: ""
      }
    };
  },
  computed: {
    ...mapGetters(["account"])
  },
  methods: {
    ...mapActions([
      "setAccount",
      "setLogin",
      "setDialogVisible",
      "setUserInfo"
    ]),
    validateLoginForm() {
      if (!this.loginForm.account) {
        this.loginForm.valid = false;
        this.loginForm.tip = this.$t("login_tip2");
        return;
      }
      if (!this.loginForm.password) {
        this.loginForm.valid = false;
        this.loginForm.tip = this.$t("login_tip3");
        return;
      }
      this.loginForm.valid = true;
      this.loginForm.tip = "";
    },
    formFocus() {
      this.loginForm.valid = true;
    },
    async login() {
      this.validateLoginForm();
      if (this.loginForm.valid) {
        this.loading = true;
        const res = await this.$http.get("/api/v1/Login", {
          params: {
            userName: this.loginForm.account,
            password: this.loginForm.password
          }
        });
        if (res.status === 200) {
          localStorage.setItem("userid", res.data.id);
          this.setLogin(true);
          this.setRememberInfo();

          this.setAccount(res.data);

          const { data } = await this.$http.get(
            "/api/v1/Accounts/" + res.data.id
          );

          if (data.id) {
            this.setAccount(data);
            this.$http.get("api/v1/Users/" + data.id).then(res => {
              if (res.status === 200) {
                this.setUserInfo(res.data);
                if (!res.data.birthday || !res.data.gender) {
                  this.$alert(this.$t("userinfo_tip2"), {
                    title: this.$t("tip"),
                    confirmButtonText: this.$t("immediately_go"),
                    type: "warning",
                    callback: () => {
                      this.$router.push({
                        path: "/center/setting",
                        query: { tab: "user" }
                      });
                    }
                  });
                }
              }
            });
          }
          this.setDialogVisible({ login: false, forgot: false });
          this.$router.push(this.$route.query.redirect || "/center");
        } else {
          this.loginForm.valid = false;
          this.loginForm.tip = res.statusText;
        }
        this.loading = false;
      }
    },
    setRememberInfo: function() {
      if (this.loginForm.remember) {
        let { account, remember, password } = this.loginForm;
        setLocalstorage({
          account,
          remember,
          password: btoa(password)
        });
      } else {
        setLocalstorage({
          account: "",
          password: ""
        });
      }
    },
    isRemembered(remember) {
      return remember === "true";
    }
  },
  created() {
    let { account, remember, password } = localStorage;
    if (account && this.isRemembered(remember)) {
      this.loginForm.account = account;
      this.loginForm.password = atob(password);
    }
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
