<template>
  <div class="footer">
    <div class="footer-l">
      <div class="footer-l-row">
        <div class="footer-l-row__imgl"></div>
        <div class="footer-l-row__imgr"></div>
      </div>
      <div class="footer-l-row">
        <span @click="na('/help')">{{ $t("footer_tip") }}</span>
        <span class="line">|</span>
        <span @click="na('/help?key=' + $t('footer_tip1'))">{{
          $t("footer_tip1")
        }}</span>
        <span class="line">|</span>
        <span @click="na('/help')">{{ $t("footer_tip2") }}</span>
        <span class="line">|</span>
        <span @click="na('/help?key=' + $t('footer_tip3'))">{{
          $t("footer_tip3")
        }}</span>
        <span class="line">|</span>
        <span @click="na('/help?key=' + $t('footer_tip4'))">{{
          $t("footer_tip4")
        }}</span>
        <span class="line">|</span>
        <span @click="na('/help?key=' + $t('footer_tip7'))">{{
          $t("footer_tip7")
        }}</span>
        <span class="line">|</span>
        <span @click="na('/help?key=' + $t('footer_tip7_1'))">{{
          $t("footer_tip7_1")
        }}</span>
      </div>
      <div class="footer-l-row">
        {{ $t("footer_tip5")
        }}<a href="https://beian.miit.gov.cn" target="_blank">{{
          $t("footer_tip6")
        }}</a>

        <span class="footer-l-row__gongan"
          ><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011202000756"
            target="_blank"
            >{{ $t("footer_tip8") }}</a
          >
        </span>

        <span class="footer-l-row__kf">客服邮箱：member@baishulai.com</span>
      </div>
    </div>
    <div class="footer-r">
      <div class="footer-r-left">
        <div class="footer-r-row">
          <div class="footer-r-row__imgt">
            <div class="footer-r-row__imgt-qrcode"></div>
          </div>
        </div>
        <div class="footer-r-row">
          <div class="footer-r-row__imgbl"></div>
          <span>{{ $t("wx_public") }}</span>
        </div>
      </div>

      <div class="footer-r-right">
        <div class="footer-r-row">
          <div class="footer-r-row__imgt">
            <div class="footer-r-row__imgt-qrcodex"></div>
          </div>
        </div>
        <div class="footer-r-row">
          <div class="footer-r-row__imgbl"></div>
          <span>{{ $t("wx_kf") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "../../mixin/common.mixin";
export default {
  mixins: [commonMixin],
  methods: {
    na(to) {
      //window.document.scrollingElement.scrollTo(0, 0);
      this.$router.push(
        to,
        () => {},
        () => {}
      );
    }
  }
};
</script>

<style src="./index.scss" lang="scss" scoped />
