export const LANG_MAP = {
  zh_CN: "zh-CN",
  zh_TW: "zh-tw",
  en_US: "en",
  ja_JP: "ja",
  ko_KR: "ko",
  ru_RU: "ru"
};

export const qqLoginHref = "https://www.sudiaoba.com/QQAuthorize.aspx";
export const aliLoginHref = "https://www.sudiaoba.com/AlipayAuthorize.aspx";
export const wxLoginHref = "https://www.sudiaoba.com/WeChatAuthorize.aspx";
