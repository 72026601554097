<template>
  <!-- registry -->
  <div class="registry-form">
    <el-alert
      v-if="!registryForm.valid"
      class="registry-error"
      :title="registryForm.tip"
      type="error"
      show-icon
    >
    </el-alert>
    <el-form
      ref="registryForm"
      :model="registryForm"
      @keyup.enter.native="registry()"
    >
      <el-form-item>
        <el-input
          v-model="registryForm.phoneCode"
          :placeholder="$t('registry_tip')"
          auto-complete="new-password"
          @focus="formFocus"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              v-model="registryForm.verifyCode"
              :placeholder="$t('registry_tip2')"
              auto-complete="new-password"
              @focus="formFocus"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              style="width:100%;"
              :disabled="sendVerifyCodeBtnStatus.disabled"
              :loading="sendVerifyCodeBtnStatus.loading"
              @click="sendVerifyCode"
            >
              {{
                sendVerifyCodeBtnStatus.disabled
                  ? sendVerifyCodeBtnStatus.time
                  : sendVerifyCodeBtnStatus.text
              }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="registryForm.password"
          :placeholder="$t('registry_tip3')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="registryForm.confirmpassword"
          :placeholder="$t('registry_tip4')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <div v-if="!isForgotMode">
        <el-checkbox v-model="agreeAgreements" @change="formFocus()">{{
          $t("i_agree")
        }}</el-checkbox>
        <el-link @click="memberAgreementVisible = true"
          >《{{ $t("member_agreement") }}》</el-link
        ><el-link @click="privacyAgreementVisible = true"
          >《{{ $t("privacy_agreement") }}》</el-link
        >
      </div>
      <el-dialog
        width="848px"
        :title="$t('sudiaoba_registry_tip')"
        append-to-body
        :visible.sync="memberAgreementVisible"
      >
        <span class="title-lg text-bold" slot="title">{{
          $t("sudiaoba_registry_tip")
        }}</span>
        <div v-html="memberAgreementHtml"></div>
      </el-dialog>
      <el-dialog
        width="848px"
        :title="$t('privacy_agreement')"
        append-to-body
        :visible.sync="privacyAgreementVisible"
      >
        <span class="title-lg text-bold" slot="title">{{
          $t("privacy_agreement")
        }}</span>
        <div v-html="privacyAgreementHtml"></div>
      </el-dialog>
      <el-button
        :loading="registryLoading"
        class="registry-btn"
        type="primary"
        round
        @click="registry()"
      >
        {{ isForgotMode ? $t("confirm_reset") : $t("registry") }}
      </el-button>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { geetestMixin } from "../../../mixin/geetest.mixin";
import Fingerprint from "../../../common/fingerprint";

export default {
  name: "registry",
  data() {
    return {
      registryLoading: false,
      committing: false,
      errMsg: " ",
      agreeAgreements: false,
      sendVerifyCodeBtnStatus: {
        disabled: false,
        time: 180,
        text: this.$t("get_vcode"),
        loading: false
      },
      registryForm: {
        phoneCode: "",
        verifyCode: "",
        password: "",
        confirmpassword: "",
        valid: true,
        tip: ""
      },
      registryRules: {
        phoneCode: [
          {
            regexp: /^1[3-9]\d{9}$/,
            message: this.$t("set_correct_mobile"),
            type: "string"
          }
        ],
        verifyCode: []
      },
      memberAgreementVisible: false,
      privacyAgreementVisible: false,
      memberAgreementHtml: this.$t("MemberAgreementHtml"),
      privacyAgreementHtml: this.$t("PrivacyAgreementHtml")
    };
  },
  props: ["isForgotMode"],
  mixins: [geetestMixin],
  methods: {
    ...mapActions([
      "setAccount",
      "setLogin",
      "setDialogVisible",
      "setUserInfo"
    ]),
    ...mapGetters(["lang"]),
    validateRegistryForm(isSendVerifyCode = false) {
      if (!this.registryForm.phoneCode) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("set_common_mobile");
        return;
      }
      if (
        !this.registryRules.phoneCode[0].regexp.test(
          this.registryForm.phoneCode
        )
      ) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip1");
        return;
      }
      // 如果是发送验证码，不会往下发送请求
      if (isSendVerifyCode) {
        return;
      }
      if (!this.registryForm.verifyCode) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip2");
        return;
      }
      if (!this.registryForm.password) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip3");
        return;
      }
      if (
        !/^(?![0-9]+$)(?![a-zA-Z]+$)(?![0-9\W]+$)(?![a-zA-Z\W]+$)[0-9A-Za-z\S]{6,64}$/.test(
          this.registryForm.password
        )
      ) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip4");
        return;
      }
      if (this.registryForm.password !== this.registryForm.confirmpassword) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip5");
        return;
      }
      if (!this.isForgotMode && !this.agreeAgreements) {
        this.registryForm.valid = false;
        this.registryForm.tip = this.$t("registry_field_tip6");
        return;
      }
    },
    formFocus() {
      this.registryForm.valid = true;
    },
    async registry() {
      this.validateRegistryForm();
      if (this.registryForm.valid) {
        this.registryLoading = true;

        const res = await this.$http.post(
          this.isForgotMode
            ? `/api/v1/Accounts/${this.registryForm.phoneCode}/Password`
            : "/api/v1/Registry",
          null,
          {
            params: Object.assign(
              {},
              {
                userName: this.registryForm.phoneCode,
                verifyCode: this.registryForm.verifyCode,
                password: this.registryForm.password,
                confirmedPassword: this.registryForm.confirmpassword
              },
              !this.isForgotMode
                ? {
                    ...this.$route.query,
                    recommendedCode: localStorage.getItem("recommendedCode"),
                    fingerprintCode: new Fingerprint().get()
                  }
                : {}
            )
          }
        );
        if (res.status === 200) {
          this.$notify.success(
            this.isForgotMode
              ? this.$t("registry_field_tip7")
              : this.$t("registry_field_tip8")
          );
          this.registryForm = {
            valid: true
          };

          localStorage.setItem("userid", res.data.id);
          this.setLogin(true);

          this.setAccount(res.data);

          const { data } = await this.$http.get(
            "/api/v1/Accounts/" + res.data.id
          );

          if (data.id) {
            this.setAccount(data);
            this.$http.get("api/v1/Users/" + data.id).then(res => {
              this.setUserInfo(res.data);
              if (!res.data.birthday || !res.data.gender) {
                this.$alert(this.$t("userinfo_tip2"), {
                  title: this.$t("tip"),
                  confirmButtonText: this.$t("immediately_go"),
                  type: "warning",
                  callback: () => {
                    this.$router.push({
                      path: "/center/setting",
                      query: { tab: "user" }
                    });
                  }
                });
              }
            });
          }
          this.setDialogVisible({ login: false, forgot: false });

          this.$router.push("center");
        } else {
          this.registryValidateError(res.statusText);
        }
        this.registryLoading = false;
      }
    },
    registryValidateError(tip) {
      this.registryForm.valid = false;
      this.registryForm.tip = tip;
      return;
    },
    async sendVerifyCode() {
      this.formFocus();
      this.validateRegistryForm(true);
      if (!this.registryForm.valid) {
        return;
      }
      try {
        this.sendVerifyCodeBtnStatus.loading = true;
        if (this.isForgotMode) {
          this.doGeetest();
        } else {
          const res = await this.$http.get("/api/v1/Registered", {
            params: {
              phoneCode: this.registryForm.phoneCode
            }
          });
          if (res.status !== 200) {
            this.registryValidateError(res.statusText);
          } else if (res.status === 200 && res.data.exists === true) {
            this.registryValidateError(this.$t("registry_field_tip9"));
          } else {
            // 进行极验校验
            this.doGeetest();
          }
        }
      } finally {
        this.sendVerifyCodeBtnStatus.loading = false;
      }
    },
    async confirmSendVerifyCode() {
      const sendRes = await this.$http.post(
        this.isForgotMode ? "/api/v1/PhoneSecurityCode" : "/api/v1/RegistryCode",
        null,
        {
          params: {
            phoneCode: this.registryForm.phoneCode,
            challenge: this.geetest.geetestSuccessData.geetest_challenge,
            validate: this.geetest.geetestSuccessData.geetest_validate,
            seccode: this.geetest.geetestSuccessData.geetest_seccode
          }
        }
      );
      console.log(sendRes);
      if (sendRes.status === 200) {
        this.$notify.success(sendRes.data && sendRes.data.vacantNumber ? this.$t("registry_field_tip11") :this.$t("registry_field_tip10"));
        this.sendVerifyCodeBtnStatus.disabled = true;
        const timer = setInterval(() => {
          this.sendVerifyCodeBtnStatus.time = this.sendVerifyCodeBtnStatus.time -= 1;
          if (this.sendVerifyCodeBtnStatus.time === 1) {
            this.sendVerifyCodeBtnStatus = {
              disabled: false,
              text: this.$t("resend"),
              time: 180
            };
            clearInterval(timer);
          }
        }, 1000);
      } else {
        return this.registryValidateError(sendRes.statusText);
      }
    },
    async doGeetest() {
      this.errMsg = "";
      if (!this.geetest.geetestObj) {
        /*
          如果this.geetest.geetestFatched==true，则说明已经加载过极验了
          如果this.geetest.showGeetest==false，则说明后台关闭极验了
         */
        if (this.geetest.geetestFatched && !this.geetest.showGeetest) {
          this.confirmSendVerifyCode();
        } else {
          this.initGeetest({
            product: "bind",
            lang: this.getLang.code
          });
        }
      } else {
        if (this.geetest.showGeetest) {
          this.geetestShow();
        } else {
          this.confirmSendVerifyCode();
        }
      }
    },
    // 极验验证成功后回调
    onGeetestSuccess() {
      // 用户通过极验行为验证后做的操作
      this.confirmSendVerifyCode();
    },
    // 极验被禁用后回调
    onDisableGeetest() {
      this.confirmSendVerifyCode();
    }
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
