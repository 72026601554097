<template>
  <div
    :class="['header', 'header_main', { isTransparent: displayTransparent }]"
  >
    <router-link :to="account.userName ? '/center' : '/home'">
      <div class="logo"></div>
    </router-link>
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      :background-color="displayTransparent ? 'transparent' : '#313132'"
      text-color="#838383"
      active-text-color="#fff"
      class="header-nav-menu"
    >
      <el-menu-item
        @click="navigate(account.userName ? '/center' : '/home')"
        index="home"
        >{{ $t("first_page") }}</el-menu-item
      >
      <el-menu-item @click="navigate('/tasks')" index="tasks">{{
        $t("tasks")
      }}</el-menu-item>
      <el-menu-item @click="navigate('/changes')" index="changes">{{
        $t("exchange")
      }}</el-menu-item>
      <el-menu-item @click="navigate('/activities')" index="activities">{{
        $t("activities")
      }}</el-menu-item>
      <el-menu-item @click="navigate('/help')" index="help">{{
        $t("help")
      }}</el-menu-item>
      <el-menu-item
        @click="open('https://www.sudiaoba.com/Default.aspx')"
        index="old"
      >
        <span
          style="border-radius: 10px; background-color: #ffa200; padding: 5px 10px; color: #fff"
        >
          {{ $t("old_version") }}
        </span>
      </el-menu-item>
    </el-menu>
    <el-button
      v-if="!account.userName"
      type="primary"
      class="header_btn_wrapper"
      plain
      round
      @click="setDialogVisible({ login: true })"
    >
      <span class="left hover">{{ $t("login") }}</span>
      <span class="line"></span>
      <span class="right hover">{{ $t("registry") }}</span>
    </el-button>
    <div v-if="account.userName" class="user-header-menu">
      <el-dropdown @command="handleCommand" trigger="click">
        <div class=" el-dropdown-link">
          <el-avatar :size="50" :src="account.avatarUrl"></el-avatar>
          <span>
            {{ userInfo.name || account.userName }}
          </span>
        </div>
        <el-dropdown-menu class="header-bar-user-menu" slot="dropdown">
          <el-dropdown-item command="center">{{
            $t("my_info")
          }}</el-dropdown-item>
          <el-dropdown-item command="logout">{{ $t("quit") }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      class="login-dialog"
      width="465px"
      append-to-body
      @close="setDialogVisible({ login: false })"
      :visible="!!dialogVisible && !!dialogVisible.login"
    >
      <Login :isRegistry="isRegistry" />
    </el-dialog>

    <el-dialog
      class="edit-mobile-dialog"
      width="465px"
      :title="$t('edit_mobile')"
      append-to-body
      @close="setDialogVisible({ editMobile: false })"
      :visible="!!dialogVisible && !!dialogVisible.editMobile"
    >
      <EditMobile />
    </el-dialog>

    <el-dialog
      class="forgot-password-dialog"
      width="465px"
      append-to-body
      :title="$t('reset_password')"
      @open="setDialogVisible({ login: false, forgot: true })"
      @close="setDialogVisible({ login: true, forgot: false })"
      :visible="!!dialogVisible && !!dialogVisible.forgot"
    >
      <RegistryForm :isForgotMode="true"></RegistryForm>
    </el-dialog>

    <share-survey></share-survey>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Login from "../login";
import RegistryForm from "components/login/registry-form";
import ShareSurvey from "components/share-survey-dialog";
import EditMobile from "components/edit-mobile";

export default {
  name: "nav-header",
  data() {
    return {
      activeIndex: "",
      loginActive: "login",
      displayTransparent: false,
      isRegistry: false
    };
  },
  props: ["isTransparent"],
  watch: {
    $route: "changeActive"
  },
  computed: {
    ...mapGetters(["account", "userInfo", "loading", "dialogVisible"])
  },
  methods: {
    ...mapActions(["setAccount", "setLogin", "setDialogVisible"]),
    navigate(to) {
      //console.log("to ===>" + to + ", name ===> " + this.$route.name);
      if (
        to === this.$route.name ||
        (["/", "center", "home"].includes(to) &&
          ["/", "center", "home"].includes(this.$route.name))
      )
        return;
      this.$router.push(
        to,
        () => {},
        () => {}
      );
    },
    open(to) {
      window.open(to, "_self");
    },
    changeActive() {
      this.activeIndex =
        this.$route.meta.actived === "center"
          ? "home"
          : this.$route.meta.actived;
    },
    handleCommand(command) {
      if (command === "logout") {
        //signout from remote
        this.$http.get(`/api/v1/Exit`);

        this.setLogin(false);
        this.setAccount({});
        localStorage.setItem("userid", "");
        this.$router.push({ path: "/", query: { showLogin: true } });
      } else if (command === "center") {
        this.$router.push(
          { path: "/center" },
          () => {},
          () => {}
        );
      }
    }
  },
  created() {
    const reCode = this.$route.query.uid;
    this.isRegistry = this.$route.query.isRegistry;
    if (reCode) {
      localStorage.setItem("recommendedCode", reCode.split(" ")[0]);
    }
    this.setDialogVisible({ login: this.$route.query.showLogin });
    this.displayTransparent =
      (document.documentElement.scrollTop || document.body.scrollTop) === 0 &&
      this.isTransparent;
    window.onscroll = () => {
      this.displayTransparent =
        (document.documentElement.scrollTop || document.body.scrollTop) === 0 &&
        this.isTransparent;
    };
    this.activeIndex = this.$route.meta.actived;
  },
  mounted() {
    this.isHome = this.$route.name === "home";
  },
  components: {
    Login,
    RegistryForm,
    ShareSurvey,
    EditMobile
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
