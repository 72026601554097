<template>
  <div class="global-login-box">
    <el-menu
      :default-active="activeMenu"
      class="el-menu-demo"
      mode="horizontal"
      @select="selectMenu"
    >
      <el-menu-item index="login">{{ $t("account_login") }}</el-menu-item>
      <el-menu-item index="registry">{{ $t("account_registry") }}</el-menu-item>
    </el-menu>
    <LoginForm v-if="activeMenu === 'login'"></LoginForm>
    <RegistryForm v-if="activeMenu === 'registry'"></RegistryForm>

    <!-- third-login -->
    <!--
    <div class="third-login">
      <div class="third-login-text">{{ $t("third_part_login") }}</div>
      <el-row :gutter="30">
        <el-col :span="8"
          ><div class="third-login-logo qq" @click="openWindow('qq')"></div>
          QQ</el-col
        >
        <el-col :span="8"
          ><div class="third-login-logo wx" @click="openWindow('wx')"></div>
          {{ $t("wx") }}</el-col
        >
        <el-col :span="8"
          ><div class="third-login-logo zfb" @click="openWindow('zfb')"></div>
          {{ $t("alipay") }}</el-col
        >
      </el-row>
    </div>
    -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RegistryForm from "./registry-form";
import LoginForm from "./login-form";
import { aliLoginHref, qqLoginHref, wxLoginHref } from "../../common/constants";

export default {
  name: "global-login-box",
  data() {
    return {
      displayTransparent: false,
      activeMenu: "login"
    };
  },
  props: ["isRegistry"],
  created() {
    if (this.isRegistry) {
      this.activeMenu = "registry";
    }
  },
  computed: {
    ...mapGetters(["account", "loading"])
  },
  methods: {
    ...mapActions(["setAccount", "setLogin"]),
    selectMenu(index) {
      this.activeMenu = index;
    },
    openWindow(type) {
      if (type === "wx") {
        window.open(wxLoginHref);
      } else if (type === "zfb") {
        window.open(aliLoginHref);
      } else if (type === "qq") {
        window.open(qqLoginHref);
      }
    }
  },
  components: {
    RegistryForm,
    LoginForm
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
