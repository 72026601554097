<template>
  <div class="share-survey-dialog">
    <el-dialog
      width="560px"
      append-to-body
      :title="$t('tip')"
      :visible="!!dialogVisible.shareSurvey"
    >
      {{ $t("survey_tip1") }}
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDialogVisible({ shareSurvey: false })">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="confirm()" v-loading="loading">{{
          $t("confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      width="560px"
      append-to-body
      :title="$t('share')"
      :visible="shareInnerDialogVisible"
    >
      <div v-if="shareInfo.type === 2" v-html="shareInfo.content"></div>
      <img
        style="width:100%"
        v-if="shareInfo.type === 1"
        :src="shareInfo.prefix + shareInfo.path"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeAll()">{{
          $t("confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Fingerprint from "../../common/fingerprint";

export default {
  data() {
    return {
      loading: false,
      shareInnerDialogVisible: false,
      shareInfo: {}
    };
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    closeAll() {
      this.shareInnerDialogVisible = false;
    },
    async confirm() {
      // this.setDialogVisible({ shareSurvey: false });
      try {
        this.loading = true;
        const res = await this.$http.post("/api/v1/BillWithoutKey", null, {
          params: {
            id: this.dialogVisible.shareSurvey,
            fingerprintCode: new Fingerprint().get()
          }
        });
        if (res.status === 200) {
          this.shareInfo = res.data;
          this.setDialogVisible({ shareSurvey: false });
          this.shareInnerDialogVisible = true;
        } else {
          this.$message.error(res.statusText);
        }
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(["dialogVisible"])
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
